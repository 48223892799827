import './App.css';
import React from 'react';
import Tableau from "./Components/Tableau"
import Access from "./Components/Access"

function App() {
  const [access, setAccess] = React.useState(false)

  return (
    <div>
      {access?<Tableau />:<Access setAccess={setAccess} />}
    </div>
  );
}

export default App;
