import React, { Component } from "react";
import Papa from "papaparse";
import RawData from '../content.csv'

export default class Tableau extends Component {
    constructor(props) {
        super(props);
        this.state = {
            parsedData: [],
            list: {
                titre: [],
                sousTitre: [],
                auteurs: [],
                type: []
            },
            filter: ['',''],
            value: {
                niveau :"",
                titre_du_cours: "",
                sous_titre_du_cours: "",
                auteur: "",
                type_de_doc: ""
            },
            over: -1,
        };
    }

    componentDidMount() {
        fetch(RawData).then((c) => c.text())
            .then(text => {
                Papa.parse(text, {
                    delimiter: ',', header: true, complete: (parsedData) => {
                        const data = parsedData.data;
                        var newListTitre = [];
                        var newListSousTitre = [];
                        var newListAuteurs = [];
                        var newListType = [];
                        for (let index = 0; index < data.length; index++) {
                            const element = data[index];
                            if (!(newListTitre.indexOf(element.titre_du_cours) > -1)) newListTitre.push(element.titre_du_cours);
                            if (!(newListSousTitre.indexOf(element.sous_titre_du_cours) > -1)) newListSousTitre.push(element.sous_titre_du_cours);
                            if (!(newListAuteurs.indexOf(element.auteur) > -1)) newListAuteurs.push(element.auteur);
                            if (!(newListType.indexOf(element.type_de_doc) > -1)) newListType.push(element.type_de_doc);
                        }
                        this.setState({ parsedData: data, list: { titre: newListTitre, sousTitre: newListSousTitre, auteurs: newListAuteurs, type: newListType } })
                    }
                })
            })
    }

    tabGenerator = () => {
        if (this.state.parsedData.length > 0) {
            var table = [];
            var k = 0;
            for (let index = 0; index < this.state.parsedData.length; index++) {
                const element = this.state.parsedData[index];
                var filter = true;
                if (this.state.filter[1] !== '') {
                    filter = element[this.state.filter[0]] === this.state.filter[1];
                }
                if (filter) {
                    const classTr = (k % 2 > 0 ? "impair" : 'pair');
                    k = k+1;
                    table.push(
                        <tr onMouseEnter={() => this.setState({over:index})} onMouseLeave={() => this.setState({over:-1})} key={"list" + index} className={classTr}>
                            <td style={this.state.over===index?{backgroundColor:"#c20000",color:"white"}:{}} className="td_1">{element.niveau}</td>
                            <td style={this.state.over===index?{backgroundColor:"#c20000",color:"white"}:{}} className="td_2"><div className="inText">{element.titre_du_cours}</div></td>
                            <td style={this.state.over===index?{backgroundColor:"#c20000",color:"white"}:{}} className="td_3"><div className="inText">{element.sous_titre_du_cours}</div></td>
                            <td style={this.state.over===index?{backgroundColor:"#c20000",color:"white"}:{}} className="td_4"><div className="inText">{element.auteur}</div></td>
                            <td style={this.state.over===index?{backgroundColor:"#c20000",color:"white"}:{}} className="td_5"><div className="inText">{element.type_de_doc}</div></td>
                            <td style={this.state.over===index?{backgroundColor:"#c20000"}:{}} className="td_6"><a style={this.state.over===index?{filter:"invert(100%)", WebkitFilter:"invert(100%)"}:{}} className="icon_file" rel="noreferrer" target={"_blank"} href={element.link}>Voir</a></td>
                        </tr>
                    )
                }
            }
            return (
                table
            )
        }
        else {
            return (null);
        }
    }

    setFilter = (category, filter) => {
        var newValue = {
            niveau :"",
            titre_du_cours: "",
            sous_titre_du_cours: "",
            auteur: "",
            type_de_doc: ""
        }
        if (category && filter !== '') {
            newValue[category] = filter;
            this.setState({filter: [category, filter], value: newValue})
        }
        else {
            this.setState({filter: ['',''], value: newValue})
        }
    }

    render() {
        return (
            <div style={{ width: "60%", marginLeft: "auto", marginRight: "auto" }}>
                <div>
                    <div className="title">
                        COURS CARDIMAGE
                    </div>
                    <div className="subTitle">
                        COURS NATIONAUX COMMENTES D’ECHOCARDIOGRAPHIE (CARDIMAGE)
                    </div>
                </div>
                <table className="tab_videos_sessions" id="tab_filtres">
                    <thead>
                        <tr>
                            <th className="td_1">
                                <select value={this.state.value.niveau} onChange={(v) => {
                                    this.setFilter('niveau', v.target.value)
                                }} name="filtre_niveau_etudiant" id="filtre_niveau_etudiant">
                                    <option value={""}>Niveau</option>
                                    <option value={"1"}>1</option>
                                    <option value={"2"}>2</option>
                                </select>
                            </th>
                            <th className="td_2">
                                <select value={this.state.value.titre_du_cours} onChange={(v) => {
                                    this.setFilter('titre_du_cours', v.target.value)
                                }} name="filtre_titre_cours" id="filtre_titre_cours">
                                    <option value={""}>Titre du cours</option>
                                    {this.state.list.titre.map((v, i) => {
                                        return (<option key={"titreCours" + i} value={v}>{v}</option>)
                                    })}
                                </select>
                            </th>
                            <th className="td_3">
                                <select value={this.state.value.sous_titre_du_cours} onChange={(v) => {
                                    this.setFilter('sous_titre_du_cours', v.target.value)
                                }} name="filtre_sous_titre_cours" id="filtre_sous_titre_cours">
                                    <option value={""}>Sous titre du cours</option>
                                    {this.state.list.sousTitre.map((v, i) => {
                                        return (<option key={"sousTitreCours" + i} value={v}>{v}</option>)
                                    })}
                                </select>
                            </th>
                            <th className="td_4">
                                <select value={this.state.value.auteur} onChange={(v) => {
                                    this.setFilter('auteur', v.target.value)
                                }} name="filtre_auteur" id="filtre_auteur">
                                    <option value={""}>Auteur</option>
                                    {this.state.list.auteurs.map((v, i) => {
                                        return (<option key={"Auteur" + i} value={v}>{v}</option>)
                                    })}
                                </select>
                            </th>
                            <th className="td_5">
                                <select value={this.state.value.type_de_doc} onChange={(v) => {
                                    this.setFilter('type_de_doc', v.target.value)
                                }} name="filtre_filtre_type" id="filtre_type">
                                    <option value={""}>Type de doc</option>
                                    {this.state.list.type.map((v, i) => {
                                        return (<option key={"Type" + i} value={v}>{v}</option>)
                                    })}
                                </select>
                            </th>
                            <th className="td_6">
                            </th>
                        </tr>
                    </thead>
                </table>
                <table className="tab_videos_sessions tablesorter" id="tab_content">
                    <tbody>
                        {this.tabGenerator()}
                    </tbody>
                </table>
            </div>
        )
    }
}