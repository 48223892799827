import React, { Component } from "react";

export default class Tableau extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pwd: "",
        };
    }

    submitPassword = () => {
        console.log(this.state.pwd)
        if (this.state.pwd === "diu0018") {
            console.log('ok')
            this.props.setAccess(true)
        }
    }

    render() {
        return (
            <div>
                <div className="verticalHorizontalCenter">
                    <div className="title">
                        COURS CARDIMAGE
                    </div>
                    <div className="blockLog">
                        <div className="textDiu">
                            Ce contenu est réservé aux étudiants inscrits en DIU. <br />
                            Si vous êtes étudiants et que vous souhaitez accéder à cette partie du site, veuillez vous rapprocher du secrétariat de votre université qui vous fournira ce code d'accès.
                        </div>
                        <div className="logDiu">
                            <input onChange={(v) => {
                                this.setState({pwd:v.target.value})
                            }} value={this.state.pwd} type={'password'} placeholder="Code d'accès" />
                            <div onClick={() => this.submitPassword()} className="buttonDiu">
                                Ok
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}